import React from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import ImageText from './shared/ImageText';
import ModularBanner from './shared/ModularBanner';
import GradientBanner from './shared/GradientBanner';
import CTABanner from './shared/CTABanner';
import AccordionSection from './shared/AccordionSection';
import MulticolorGrid from './shared/MulticolorGrid';
import TextGrid from './shared/TextGrid';
import PhotoGallery from './shared/PhotoGallery';
import EBRGs from './shared/EBRGs';

const About = () => {
    return (
        <div>
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.ABOUT_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.ABOUT_HERO.SUB_HEADER}
                src={'/images/about-hero.png'}
                altText={'brown "welcome" doormat next to a pair of yellow sneakers and a plant'}
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.ABOUT_SIMPLE_BANNER_STRING} />
            <TextGrid strings={APPLICATION_CONSTANTS.ABOUT_TEXTGRID} /> 
            <GradientBanner type={"imagetext"} cardStrings={APPLICATION_CONSTANTS.ABOUT_IMAGETEXT_V2} />
            <AccordionSection 
                headerString={APPLICATION_CONSTANTS.ABOUT_ACCORDION_SECTION_HEADER} 
                subheaderString={APPLICATION_CONSTANTS.ABOUT_ACCORDION_SECTION_SUBHEADER}
                accordionStrings={APPLICATION_CONSTANTS.ABOUT_ACCORDION_STRINGS} 
            />
            <ImageText
                src={'/images/about-textimage.jpg'}
                altText={'a group of people sitting together, listening and taking notes'}
                mainHeader={APPLICATION_CONSTANTS.ABOUT_IMAGETEXT.MAIN_HEADER}
                subHeader1={APPLICATION_CONSTANTS.ABOUT_IMAGETEXT.SUB_HEADER}
            />
            <ModularBanner 
                type={"standard"}
                columnHeaderColor={'var(--oncourse-blue)'}
                contentStrings={APPLICATION_CONSTANTS.DEI_STRINGS}
                headerString={APPLICATION_CONSTANTS.DEI_HEADER} 
                styles={{
                    backgroundColor: 'white'
                }}
            />
            <EBRGs strings={APPLICATION_CONSTANTS.EBRG_BANNER} />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.ABOUT_GRADIENTBANNER_HEADER}
                cardStrings={APPLICATION_CONSTANTS.ABOUT_GRADIENTBANNER_CARDS}
                type={"large"}
            />
            <PhotoGallery headerString={APPLICATION_CONSTANTS.PHOTO_GALLERY_GRID.HEADER} galleryImageStrings={APPLICATION_CONSTANTS.PHOTO_GALLERY_GRID.GALLERYIMAGES} />
            <PhotoGallery headerString={APPLICATION_CONSTANTS.BOARD_PHOTO_GALLERY_GRID.HEADER} galleryImageStrings={APPLICATION_CONSTANTS.BOARD_PHOTO_GALLERY_GRID.GALLERYIMAGES} />
            <PhotoGallery headerString={APPLICATION_CONSTANTS.ADVISORY_BOARD_PHOTO_GALLERY_GRID.HEADER} galleryImageStrings={APPLICATION_CONSTANTS.ADVISORY_BOARD_PHOTO_GALLERY_GRID.GALLERYIMAGES} />
            <SimpleBanner type={"quote"} string={APPLICATION_CONSTANTS.ABOUT_SIMPLE_QUOTE_BANNER} />
            <MulticolorGrid 
                headerString={APPLICATION_CONSTANTS.ABOUT_MULTICOLOR_GRID.HEADER}
                cardStrings={APPLICATION_CONSTANTS.ABOUT_MULTICOLOR_GRID.CARDS}
            />
            <CTABanner strings={APPLICATION_CONSTANTS.ABOUT_CTA_BANNER} />
        </div>
    );
};

export default About;