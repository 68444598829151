
const APPLICATION_CONSTANTS = {
    NAV_OPTIONS: ['Homeowners', 'Partners', 'Service providers', 'About us', 'Careers'],
    COLORS: {
        ONCOURSE_BLUE: 'var(--oncourse-blue)',
        SUPPORT_YELLOW: 'var(--support-yellow)',
        GREEN: 'var(--green)',
        BACKGROUND_GRAY: 'var(--background-gray)',
        WHITE: 'white',
    },
    CONTACT_US: 'CONTACT US',
    PRIVACY_HEADER: 'PRIVACY POLICY',
    LEARN_MORE: 'LEARN MORE',
    JOB_OPENINGS: 'JOB OPENINGS',
    AWR: 'American Water Resources',
    PHS: 'Pivotal Home Solutions',
    SERVICEPROVIDERS_HERO: {
        MAIN_HEADER: 'GROW YOUR BUSINESS',
        SUB_HEADER: 'Join the Oncourse Home Solutions Service Provider Network to help expand your business with access to our broad customer base, territories, and tools.',
    },
    SERVICEPROVIDERS_SIMPLE_BANNER_STRING: 'Join a network that’s 500 strong and provide customers with the superior home care they deserve.',
    SERVICEPROVIDERS_IMAGETEXT: {
        MAIN_HEADER: 'GAIN ACCESS TO 1.4 MILLION CUSTOMERS',
        SUB_HEADER1: 'Oncourse Home Solutions has been a trusted leader in home care solutions for decades, bringing customers peace of mind with all their home-related concerns. We look for skilled, trustworthy, and motivated service providers to help support our extensive customer base and swiftly care for homes.',
        SUB_HEADER2: 'It’s completely free to join our robust network — and once you’re in, we’ll be there to support you at every step of the way.',
    },
    SERVICEPROVIDERS_MODULARBANNER_STRINGS: [
        {
            MAIN_HEADER: 'JOIN THE NETWORK FOR FREE',
            SUB_HEADER: 'It’s easy and free to join the most trusted homeowner service provider in the country. Once you apply,  one of our recruiting specialists will reach out to you to review requirements and answer any questions. Then, if you are approved, we’ll get you onboarded quickly so you can start to receive claims as soon as possible!',
        },
        {
            MAIN_HEADER: 'RECEIVE JOBS IMMEDIATELY',
            SUB_HEADER: 'Oncourse Home Solutions makes it easy to receive jobs through our simple, mobile-friendly dispatch system and via email. We provide you with all the necessary information on the claim so you can contact the customer to schedule the work.',
        },
        {
            MAIN_HEADER: 'GET PAID DIRECTLY BY ONCOURSE',
            SUB_HEADER: 'Once the work is completed and the customer signs off, submit an invoice to Oncourse Home Solutions based on your contracted rates. We’ll pay you within 30 days via direct deposit to your bank account.',
        },

    ],
    SERVICEPROVIDERS_GRADIENTBANNER_MAIN_HEADER: 'UNLOCK NEW BUSINESS OPPORTUNITIES',
    SERVICEPROVIDERS_GRADIENTBANNER_CARD_STRINGS: [
        {
            MAIN_HEADER: '1',
            SUB_HEADER: 'A steady stream of jobs at no cost to you',
            CONTENT:'Once you join the network, you’re immediately assigned jobs in your area with no associated marketing costs or buying leads. We make claims routing and work order management easy, so you get plenty of jobs and get paid quickly.  ',
        },
        {
            MAIN_HEADER: '2',
            SUB_HEADER: 'Superior support to help you manage your business',
            CONTENT:'From our management team to the field managers and recruiters, we all have hands-on knowledge and years of experience working with our network of providers — and we’re focused on helping you and your business succeed.',
        },
        {
            MAIN_HEADER: '3',
            SUB_HEADER: 'New services to offer your customers',
            CONTENT: 'Oncourse Home Solutions offers a wide breadth of partners and services — from water, sewer, and electric line to whole home protection— when you join our network, you can make use of our extended services and provide your customers with additional home care support.',
        },
        {
            MAIN_HEADER: '4',
            SUB_HEADER: 'Access to valuable resources',
            CONTENT: 'We provide you with valuable resources on business growth and management strategies so you can learn best practices to increase your business, hire qualified technicians, enhance customer loyalty, expand territories and services, achieve revenue goals, and more.',
        },

    ],
    SERVICEPROVIDERS_SIMPLE_QUOTE_BANNER_STRING: {
        CONTENT: '“Joining the Provider Network has been very beneficial for my company. We have grown significantly and expanded our services. Oncourse Home Solutions is a large provider with a growing base of customers, so we receive a substantial and consistent flow of work, even during slower periods.”',
        NAME: 'Greg Fifer',
        TITLE: 'Owner, A&G Repair Service',
    },
    SERVICEPROVIDERS_CTABANNER1_STRINGS: {
        MAIN_HEADER: 'JOIN THE ONCOURSE NETWORK',
        SUB_HEADER: 'Interested in becoming an Oncourse Home Solutions service provider? Learn more about joining our national provider network and help us service our 1.4 million and growing customer base.',
    },
    SERVICEPROVIDERS_ACCORDION_SECTION_HEADER: 'WE\'VE GOT ANSWERS',
    SERVICEPROVIDERS_ACCORDION_STRINGS: [
        {
            MAIN_HEADER: 'Does it cost anything to join our Provider Network?',
            CONTENT: ['Joining our provider network is completely free — there are no fees for leads or jobs, and there’s no requirement to market your business to our customers. Begin the application process today, and one of our recruiting specialists will reach out to review requirements and answer your questions.']
        },
        {
            MAIN_HEADER: 'What do I need to provide Oncourse Home Solutions to join?',
            CONTENT: ['Before joining Oncourse Home Solutions, we kindly ask that you provide us with essential details about your business, including: your business name, tax ID number, address, among other pertinent details. We will also need to verify your applicable licenses, types of services offered, and proof of insurance. When all of these materials are completed, we’ll send you an agreement to sign electronically using DocuSign, which allows for easy review and signing of contracts.']
        },
        {
            MAIN_HEADER: 'What insurance do you require for your contractors?',
            CONTENT: ['Oncourse Home Solutions contractor insurance requirements vary by service type, location, and specific partnerships, but our recruiting team is here to guide you through the entire process. We provide you with detailed information around insurance needs and pass on all necessary documents to your insurance agent for a streamlined process.']
        },
        {
            MAIN_HEADER: 'How do I receive jobs?',
            CONTENT: ['Receiving jobs through Oncourse Home Solutions is simple — you can receive jobs through our mobile-friendly dispatch system or directly via email. Either way, we ensure that you receive all necessary information so that you can promptly contact the customer to schedule the necessary work.']
        },
        {
            MAIN_HEADER: 'Is there a cost to use the dispatch system?',
            CONTENT: ['There is no cost to use our dispatch solution — it’s free for you to manage all your customer jobs.']
        },
    ],
    SERVICEPROVIDERS_CTABANNER2_STRINGS: {
        MAIN_HEADER: 'GET IN TOUCH',
        SUB_HEADER: 'Interested in learning more about Oncourse Home Solutions? Reach out and we’ll be in touch shortly.',
    },
    FOOTER_STRINGS: {
        PHONE: '(855) 800-5195',
        EMAIL: 'customersupport@oncoursehome.com',
        LOCATION: '1415 W. Diehl Road, Ste 270, Naperville, IL, 60563',
        DISCLAIMERS: [
            'Oncourse Home Solutions is a d/b/a of Pivotal Home Solutions, LLC.',
            'New Jersey Residents: The product being offered is a service contract and is separate and distinct from any product or service warranty which may be provided by the home builder or manufacturer.'
        ],
        COPYRIGHT: 'COPYRIGHT © 2024. ALL RIGHTS RESERVED. Oncourse Home Solutions',
    },
    CAREERS_HERO: {
        MAIN_HEADER: 'MAKE A LASTING IMPACT WITH ONCOURSE HOME SOLUTIONS',
        SUB_HEADER: 'Join our 450+ employees bringing peace of mind to homeowners across the U.S.'
    },
    CAREERS_SIMPLE_BANNER_STRING: 'Join our vibrant community of innovators, problem solvers, and community leaders as we work to empower homeowners across the nation.',
    CAREERS_IMAGETEXT1: {
        MAIN_HEADER: 'DISCOVER OUR CULTURE OF INNOVATION AND COLLABORATION',
        SUB_HEADER: 'At Oncourse Home Solutions, we pride ourselves on our fast-paced, dynamic company culture that fosters growth, innovation, and collaboration. We believe that our people are our greatest asset — we’re a group of high achievers who are passionate about simplifying the lives of homeowners every day and making a lasting impact.'
    },
    CAREERS_ACCORDION_SECTION_HEADER: 'AT ONCOURSE HOME SOLUTIONS, WE EMPOWER OUR EMPLOYEES TO SUCCEED',
    CAREERS_ACCORDION_STRINGS: [
        {
            MAIN_HEADER: 'Professional development opportunities',
            CONTENT: ['We are invested in the growth and professional development of our employees. At Oncourse Home Solutions, we provide ongoing opportunities for learning and training that will support you in enhancing your skills and reaching your goals.']
        },
        {
            MAIN_HEADER: 'Meaningful work',
            CONTENT: ['At Oncourse Home Solutions, you will be part of a mission-driven company and have a direct impact on bringing peace of mind to homeowners across the country every day.']
        },
        {
            MAIN_HEADER: 'Inclusive and supportive environment',
            CONTENT: [
                'We foster a diverse, inclusive environment and we always make sure that all voices get heard. We are there to support each other in our work every day.',
                {
                    TEXT: 'Our Employee Business Resource Groups (EBRG) are the support systems within Oncourse Home Solutions that foster inclusion, collaboration, and enterprise thinking. EBRGs are a key component of Oncourse Home Solutions’ commitment to diversity, equity, and inclusion as a means to instill belonging and a sense of psychological safety in the workplace. Each EBRG focuses on the below themes:',
                    BULLETS: [
                        'Talent Acquisition, Onboarding, and Retention',
                        'Financial Literacy',
                        'Health & Wellness',
                        'Brand, Products, and Services',
                        'Environmental, Social, and Governance (ESG)',
                        'Cultural Awareness (Traditions and Celebrations)'
                    ]
                }
                
            ],
        },
        {
            MAIN_HEADER: 'Comprehensive benefits',
            CONTENT: ['We take care of our employees’ well-being, offering comprehensive benefits  to support our teams. From competitive salaries and robust healthcare plans to pet insurance, tuition reimbursement, and generous vacation time, we are invested in your overall success and happiness.']
        },
    ],
    CAREERS_IMAGETEXT2: {
        MAIN_HEADER: 'WE FOSTER AN ENVIRONMENT THAT EMBRACES DIVERSITY IN ALL FORMS',
        SUB_HEADER: 'We strive to create an inclusive work environment where everyone feels valued, respected, and supported. At Oncourse Home Solutions, we celebrate unique perspectives and provide everyone with equal opportunities to succeed. We are dedicated to focusing our Diversity, Equity, and Inclusion (DEI) efforts on our talent, business, and community as a critical element of our overall business growth.'
    },
    DEI_HEADER: 'OUR DEI COMMITMENT',
    DEI_STRINGS: [
        {
            MAIN_HEADER: 'TALENT',
            SRC: '/images/dei-icons/talent-blue.png',
            SUB_HEADER: 'We are committed to attracting, retaining, and developing diverse talent at every level. We work to eliminate barriers and provide equal opportunity for all employees to grow and succeed while addressing issues of systemic discrimination and bias through ongoing education, training, and awareness-building.',
        },
        {
            MAIN_HEADER: 'BUSINESS',
            SRC: '/images/dei-icons/business-blue.png',
            SUB_HEADER: 'We form business relationships with others who share in our commitment to DEI and who mirror the diversity of our employees, partners, customers, and the communities we serve.',
        },
        {
            MAIN_HEADER: 'COMMUNITY',
            SRC: '/images/dei-icons/community-blue.png',
            SUB_HEADER: 'We are committed to investing time and capital in communities where we work ​and live. ​Through our Oncourse Cares program, we form long-term relationships with organizations and nonprofits that help underrepresented communities. We proudly show up in volunteer and charitable donation efforts aligned with our mission, vision, and values.',
        },
    ],
    EBRG_BANNER: {
        HEADER: 'EMPLOYEE BUSINESS RESOURCE GROUPS',
        EBRGS: [
            {
                MAIN_HEADER: 'ABLE',
                SRC: '/images/ebrg-logos/able.png',
                LABEL: 'Our mission is to cultivate an inclusive workplace environment where individuals of all abilities can thrive. Through advocacy, education, and support, we empower SUPERs with disabilities and allies to champion diversity, accessibility, and equal opportunities.'
            },
            {
                MAIN_HEADER: 'BOLD',
                SRC: '/images/ebrg-logos/bold.png',
                LABEL: 'Oncourse BOLD creates a safe space for Black employees and allies to have open and honest conversations about the unique experiences of being Black in the workplace. These important discussions lead to improved employee engagement, retention, performance, and business results.'
            },
            {
                MAIN_HEADER: 'FAM',
                SRC: '/images/ebrg-logos/fam.png',
            },
            {
                MAIN_HEADER: 'O2W',
                SRC: '/images/ebrg-logos/o2w-win.png',
                LABEL: 'In support of our DEI commitment, and through the experiences of Women and their allies, promote personal and professional growth while contributing to the business of Oncourse Home Solutions.'
            },
            {
                MAIN_HEADER: 'SOMOS',
                SRC: '/images/ebrg-logos/somos.png',
                LABEL: 'The Hispanic EBRG is committed to promoting cultural diversity and professional development of the Hispanic/Latinx community within Oncourse Home Solutions; thereby fostering a supportive environment for its members and furthering the success of our greater Oncourse mission.'
            },
            {
                MAIN_HEADER: 'VAEV',
                SRC: '/images/ebrg-logos/vaev.png',
                LABEL: 'As a Veterans and allies focused EBRG, we honor, support, and empower veterans within our organization. Through ongoing education, advocacy for unique needs, and providing resources and networking opportunities, we are championing diversity for our veterans and allies, while creating a workplace where all feel valued, respected, and empowered. '
            },
        ],
        SUB_HEADER: 'EBRGs are support systems within Oncourse that foster inclusion, collaboration, and enterprise thinking.',
        DESC: 'EBRGs are a key component of our commitment to diversity, equity, and inclusion as a means to instill belonging and psychological safety in the workplace.',
    },
    CAREERS_SIMPLE_QUOTE_BANNER_STRING: {
        CONTENT: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent non placerat sem. Sed malesuada placerat leo. Praesent et lacus id ipsum cursus efficitur.”',
        NAME: 'Derek S. Green',
        TITLE: 'Former Councilmember, Philadelphia',
    },
    CAREERS_CTABANNER_STRINGS: {
        MAIN_HEADER: 'GET IN TOUCH',
        SUB_HEADER: 'Interested in learning more about Oncourse Home Solutions? Reach out and we’ll be in touch shortly.',
    },
    CAREERS_OPEN_POSITIONS: {
        MAIN_HEADER: 'OPEN POSITIONS',
        SUB_HEADER: 'Whether you’re an experienced professional or just starting your career, there’s a place for you to thrive at Oncourse Home Solutions. Take the next step and embark on a rewarding career with us.',
        LEARN_MORE: 'LEARN MORE',
    },
    ABOUT_HERO: {
        MAIN_HEADER: 'WE MAKE IT SIMPLE TO CARE FOR YOUR HOME',
        SUB_HEADER: 'We provide coverage that’s simple to understand and intuitive to use so homeownership can be hassle-free.'
    },
    ABOUT_SIMPLE_BANNER_STRING: 'With over two decades of experience, we provide expert home solutions that simply work.',
    ABOUT_TEXTGRID: {
        MAIN_HEADER: 'SIMPLIFYING HOME CARE TO BRING PEACE OF MIND TO HOMEOWNERS',
        SUB_HEADER1: 'With over two decades of experience, Oncourse Home Solutions is here to support homeowners, utilities, and municipal customers nationwide. Our services, originating from Pivotal Home Solutions, American Home Solutions, and American Water Resources, ensure the highest level of service and protection for your essential home systems.',
        SUB_HEADER2: 'We offer a range of home protection plans, covering unexpected costs and providing on-demand services for repairs, maintenance, and improvements. Backed by a vast network of skilled providers, we swiftly handle everything from water, sewer, and electric lines to whole home protection. We always put the people and homes we serve first, offering peace of mind through it all.',
    },
    ABOUT_IMAGETEXT_V2: [
        {
            HEADER1: 'OUR MISSION',
            SUB_HEADER1: 'To create lasting value for our customers and our partners by helping homeowners navigate the unexpected, reduce costs, and make homeownership enjoyable for all.',
            SRC:`/images/about-imagetextV2-img-1.png`
        },
        {
            HEADER2: 'OUR VISION',
            SUB_HEADER2: 'To make our products and services accessible to our customers and our partners by becoming the most trusted and reliable home solutions organization in the market.',
            SRC:`/images/about-imagetextV2-img-2.png`
        }
    ],
    ABOUT_ACCORDION_SECTION_HEADER: 'OUR SUPER VALUES',
    ABOUT_ACCORDION_SECTION_SUBHEADER: 'When our customers need help with home maintenance or repair, we are there. These are the minimum required behaviors of all SUPERs.',
    ABOUT_ACCORDION_STRINGS: [
        {
            MAIN_HEADER: 'Successful',
            CONTENT: ['We’re dogged in our pursuit of success and achievement across all aspects of Oncourse. We set our Company sights high. We plan to exceed the mark and create nothing short of exceptional results and customer solutions. There are no losers, only winners and learners. We always take a beat to celebrate victories, acknowledge fellow Supers, and learn from failures.'],
            SRC: '/images/value-icons/S-Trophy_icon.png'
        },
        {
            MAIN_HEADER: 'United',
            CONTENT: ['We believe in the power of community, collaboration, and working toward common goals. We value diversity, equity, and inclusion at all levels of our Company - creating an environment where all contributions are valued. We go the extra mile to help others succeed and do business with those who mirror the communities represented by our employees, partners, service providers, and homeowners.'],
            SRC: '/images/value-icons/U-Handshake_icon.png'
        },
        {
            MAIN_HEADER: 'Progressive',
            CONTENT: ['We are forward-thinkers and innovators who embrace the concept of change or be changed. We pair technical expertise with a bent for exceptional service. We’re always searching for better ways to optimize operations and provide more value to our homeowners. We’re realists who embrace diverse thoughts and perspectives - allowing us to adapt quickly to tackle challenges or seize opportunities.'],
            SRC: '/images/value-icons/P-Graph_icon.png'
        },
        {
            MAIN_HEADER: 'Empathetic',
            CONTENT: ['We strive to understand the needs and perspectives of all stakeholders, including employees, homeowners, partners, service providers, and the communities we serve. We are approachable, we actively listen and respond with thoughtful consideration - building relationships based on trust, respect, and understanding.'],
            SRC: '/images/value-icons/E-Empathy_icon.png'
        },
        {
            MAIN_HEADER: 'Reliable',
            CONTENT: ['We bring it every day. We are committed to consistently delivering high-quality, dependable solutions that meet the needs of our homeowners, partners, service providers, and fellow Supers. We take ownership and accountability for our work. We prioritize honesty and transparency in all our interactions - placing a high value on proactive, direct, and simple communication.'],
            SRC: '/images/value-icons/R-Shield_icon.png'
        },
        
    ],
    ABOUT_IMAGETEXT: {
        MAIN_HEADER: 'WE FOSTER AN ENVIRONMENT THAT EMBRACES DIVERSITY IN ALL FORMS',
        SUB_HEADER: 'We strive to create an inclusive work environment where everyone feels valued, respected, and supported. At Oncourse Home Solutions, we celebrate unique perspectives and provide everyone with equal opportunities to succeed. We are dedicated to focusing our Diversity, Equity, and Inclusion (DEI) efforts on our talent, business, and community as a critical element of our overall business growth.'
    },
    ABOUT_GRADIENTBANNER_HEADER: 'POWERED BY ICONIC BRANDS',
    ABOUT_GRADIENTBANNER_CARDS: [
        {
            SRC: `/images/awr-logo.svg`,
            ALT: 'American Water Resources logo',
            CONTENT: 'American Water Resources offers home warranty plans for homeowners, including utility and municipal customers in 43 states since 2000. We cover: water lines, sewer lines, in-home plumbing, heating, cooling, and interior and exterior electric lines.',
            LINK: 'VISIT THE SITE',
            HREF: 'https://www.awrusa.com/'
        },
        {
            SRC: `/images/phs-logo.svg`,
            ALT: 'Pivotal Home Solutions logo',
            CONTENT: 'Pivotal Home Solutions has provided home warranty protection for home systems, lines, and appliances in 16 states since 1992. Our service provider network includes expert technicians that handle heating, cooling, and plumbing repairs as needed. Pivotal Home Solutions is becoming Oncourse Home Solutions.',
            LINK: 'VISIT THE SITE',
            HREF: 'https://www.yourhomesolutions.com/'
        },
        {
            SRC: `/images/ahs-logo.svg`,
            ALT: 'American Home Solutions logo',
            CONTENT: 'American Home Solutions offers home warranty plans designed specifically for ONE Gas Utility customers and offers protection plans that cover critical repairs for natural gas lines, heating and cooling systems, water heaters, water and sewer service lines, plumbing and more. American Home Solutions is becoming Oncourse Home Solutions.',
            LINK: 'VISIT THE SITE',
            HREF: 'https://www.yourhomesolutions.com/one-gas'
        },
    ],
    ABOUT_SIMPLE_QUOTE_BANNER: {
        CONTENT: '“Great experience. Fast and reliable responses 24 hours a day. Reasonable rates. Outstanding customer service. I have already recommended you to as many people I can.”',
        NAME: 'Rafael M.',
        // TITLE: 'Former Councilmember, Philadelphia',
    },
    ABOUT_MULTICOLOR_GRID: {
        HEADER: 'TAKE THE NEXT STEP',
        CARDS: [
            {
                HEADER: 'PROTECT YOUR HOME',
                CONTENT: 'Grant yourself the peace of mind that comes with knowing your home is covered and protected from unexpected expenses.',
                LINK: 'HOMEOWNERS',
                HREF: '/homeowners',
                BACKGROUND_COLOR: 'var(--support-purple)',
                TEXT_COLOR: 'white',
                SRC: '/images/multicolor-grid-keys.png',
                ALT: 'a pair of keys on a ring'
            },
            {
                HEADER: 'PARTNER WITH US',
                CONTENT: 'Partner with Oncourse Home Solutions to generate revenue, increase customer satisfaction, and upgrade community infrastructure. You can rely on us.',
                LINK: 'PARTNERS',
                HREF: '/partners',
                BACKGROUND_COLOR: 'var(--support-yellow)',
                TEXT_COLOR: 'black',
                SRC: '/images/person-with-headset.png',
                ALT: 'smiling person with a headset'
            },
            {
                HEADER: 'JOIN THE PROVIDER NETWORK',
                CONTENT: 'Join our network to grow your customer base, gain access to additional jobs, and expand your business into new service offerings.',
                LINK: 'SERVICE PROVIDERS',
                HREF: '/service-providers',
                BACKGROUND_COLOR: 'var(--green)',
                TEXT_COLOR: 'black',
                SRC: '/images/person-with-hardhat.png',
                ALT: 'smiling person with a hardhat'
            },
            {
                HEADER: 'WORK WITH US',
                CONTENT: 'Join the team of 450+ employees working diligently to bring peace of mind to homeowners across the country.',
                LINK: 'CAREERS',
                HREF: '/careers',
                BACKGROUND_COLOR: 'var(--oncourse-blue)',
                TEXT_COLOR: 'white',
                SRC: '/images/person-on-phone.png',
                ALT: 'smiling person on the phone'
            },
        ]
    },
    ABOUT_CTA_BANNER: {
        MAIN_HEADER: 'GET IN TOUCH',
        SUB_HEADER: 'Interested in learning more about Oncourse Home Solutions? Reach out and we’ll be in touch shortly.',
    },
    PARTNERS_HERO: {
        MAIN_HEADER: 'TAKE YOUR BUSINESS TO THE NEXT LEVEL',
        SUB_HEADER: 'Leverage our proven solutions and extensive network to increase revenue and customer satisfaction.'
    },
    PARTNERS_SIMPLE_BANNER: 'Generate additional revenue and improve customer satisfaction.',
    PARTNERS_STATS_BANNER: [
        {
            MAIN_HEADER: '1.7 M',
            SUB_HEADER: 'Calls answered annually'
        },
        {
            MAIN_HEADER: '95%',
            SUB_HEADER: '2022 customer satisfaction post-visit'
        },
        {
            MAIN_HEADER: '350+',
            SUB_HEADER: 'Contact center agents'
        },
        {
            MAIN_HEADER: '$100 M',
            SUB_HEADER: 'In repair costs saved by customers in 2022'
        }
    ],
    PARTNERS_MODULARBANNER: {
        HEADER: {
            MAIN_HEADER: 'EMPOWER YOUR BUSINESS AND YOUR CUSTOMERS',
            SUB_HEADER: 'At Oncourse Home Solutions, we partner with utilities and municipalities to offer comprehensive home solutions that can boost customer satisfaction and generate revenue. Throughout our partnership, we prioritize your reputation and serve your customers with the utmost care.'
        },
        COLUMNS: [
            {
                MAIN_HEADER: 'UTILITY PROVIDERS',
                SUB_HEADER: 'We leverage our deep utility knowledge to enhance customer satisfaction for our partners and provide an additional revenue source. We’ll support you throughout the partnership and service your customers with the utmost care.'
            },
            {
                MAIN_HEADER: 'AFFINITY & INSURANCE',
                SUB_HEADER: 'We help you assist your customers with loss prevention, increase customer engagement and generate additional revenue. We can help take your business to the next level.'
            },
            {
                MAIN_HEADER: 'MUNICIPALITIES & CITIES',
                SUB_HEADER: 'We partner with municipalities and cities to provide optional service line protection programs. Together, we can educate homeowners on their service line responsibilities and provide a solution to unexpected repairs.'
            }
        ]
    },
    PARTNERS_GRADIENT_BANNER: {
        HEADER: 'EFFORTLESS PARTNERSHIP FOR OPTIMAL RESULTS',
        CARDS: [
            {
                MAIN_HEADER: 'Effective  marketing',
                SUB_HEADER: 'Our marketing team educates homeowners on how best to mitigate risks and optimizes enrollment in our programs.'
            },
            {
                MAIN_HEADER: 'Advanced customer support ',
                SUB_HEADER: 'Oncourse Home Solutions customer support resolves customer claims 24/7, 365 days a year — we always prioritize helping homeowners where we can.'
            },
            {
                MAIN_HEADER: 'Rigorous customer satisfaction monitoring',
                SUB_HEADER: 'We continuously monitor customer satisfaction by following up on service calls with detailed surveys and tracking responses.'
            },
            {
                MAIN_HEADER: 'Streamlined administration',
                SUB_HEADER: 'When you partner with us, you don’t have to worry about administration — utility billing options may be made available for your customers, and we maintain all financial reports for your records at zero cost.'
            },
            {
                MAIN_HEADER: 'Increased revenue and customer satisfaction',
                SUB_HEADER: 'Our established approach to partnerships and customer service offers results that may increase your revenue and your customers’ satisfaction.'
            }
        ]
    },
    PARTNERS_LOGO_BANNER:{
        HEADER: 'JOIN OUR NETWORK OF TRUSTED PARTNERS',
        ICONS: [
            '/images/partners-icons/2.png',
            '/images/partners-icons/dwsd.png',
            '/images/partners-icons/OUC.svg',
            '/images/partners-icons/ONE-Gas_LDCs.png',
            '/images/partners-icons/7.svg',
            '/images/partners-icons/9.svg',
            '/images/partners-icons/10.svg'
        ]
    },
    PARTNERS_PROTECTIONS_BANNER: {
        HEADER: 'CUSTOMIZABLE COVERAGE PLANS TO SUIT CUSTOMER NEEDS',
        SUB_HEADER: 'We have various coverage plans to allow your customers to enroll in the repair and maintenance that fit them best — be it stand-alone choices, combination packages, or curated value bundles. We also offer you the flexibility to choose which plans to offer and how to conduct your billing processes — we can bill through you or take care of it directly.',
        ICONS: [
            {
                SRC: `/images/power-cord.svg`,
                LABEL: 'UTILITY LINES', 
            },
            {
                SRC: `/images/hvac.svg`,
                LABEL: 'HVAC', 
            },
            {
                SRC: `/images/plumbing.svg`,
                LABEL: 'PLUMBING PROTECTION', 
            },
            {
                SRC: `/images/appliances.svg`,
                LABEL: 'APPLIANCES', 
            },
            {
                SRC: `/images/surge.svg`,
                LABEL: 'ELECTRONICS & SURGE', 
            },
            {
                SRC: `/images/protection.svg`,
                LABEL: 'WHOLE HOME', 
            },
        ]
    },
    PARTNERS_SIMPLE_QUOTE_BANNER: {
        CONTENT: '“Oncourse Home Solutions (OHS) has kept their promise to Philadelphia homeowners and our city. Not only have their programs protected thousands from the burden of unexpected and unavoidable repairs, but their partnership has also contributed to new job opportunities for many small business owners in the area.”',
        NAME: 'Derek S. Green',
        TITLE: 'Former Councilmember, Philadelphia',
    },
    PARTNERS_CTA_BANNER: {
        MAIN_HEADER: 'PARTNER WITH US',
        SUB_HEADER: 'Let’s work together to empower homeowners and pave the way for a future where home maintenance is effortless.'
    },
    PHOTO_GALLERY_GRID: {
        HEADER: 'EXECUTIVE MANAGEMENT COMMITTEE',
        GALLERYIMAGES: [
            {
                IMAGE: '/images/leadership-images/Aaron-Cooper.png',
                TITLE: 'Aaron Cooper',
                SUBTITLE: 'Chief Executive Officer',
            },
            {
                IMAGE: '/images/leadership-images/Arul-Sugunakumar.png',
                TITLE: 'Arul Sugunakumar',
                SUBTITLE: 'Chief Technology Officer',
            },
            {
                IMAGE: '/images/leadership-images/Colleen-Garrity.png',
                TITLE: 'Colleen Garrity',
                SUBTITLE: 'General Counsel',
            },
            {
                IMAGE: '/images/leadership-images/Josh-Cohen.png',
                TITLE: 'Josh Cohen',
                SUBTITLE: 'Chief Commercial Officer (Interim Chief Financial Officer)',
            },
            {
                IMAGE: '/images/leadership-images/Marlo-Gaal.png',
                TITLE: 'Marlo J. Gaal',
                SUBTITLE: 'Chief People and Diversity Officer',
            },
            {
                IMAGE: '/images/leadership-images/Pete-DeFrancesco.png',
                TITLE: 'Pete DeFrancesco',
                SUBTITLE: 'Chief Marketing Officer',
            },
            {
                IMAGE: '/images/leadership-images/Phillip-Edens.png',
                TITLE: 'Phillip Edens',
                SUBTITLE: 'Chief Customer Officer',
            },
            {
                IMAGE: '/images/leadership-images/open-position.png',
                TITLE: 'Open Position',
                SUBTITLE: 'Chief Financial Officer',
            }
        ]
    },
    BOARD_PHOTO_GALLERY_GRID: {
        HEADER: 'BOARD MEMBERS',
        GALLERYIMAGES: [
            {
                IMAGE: '/images/board-images/Martin-Bennett.png',
                TITLE: 'Martin Bennett',
                SUBTITLE: 'Chairman',
            },
            {
                IMAGE: '/images/board-images/Ann-Sardini.png',
                TITLE: 'Ann Sardini',
                SUBTITLE: 'Board Member',
            },
            {
                IMAGE: '/images/board-images/Ashish-Karandikar.png',
                TITLE: 'Ashish Karandikar',
                SUBTITLE: 'Board Member',
            },
            {
                IMAGE: '/images/board-images/Irene-Zou.png',
                TITLE: 'Irene Zou',
                SUBTITLE: 'Board Member',
            },
            {
                IMAGE: '/images/board-images/Nedu-Ottih.png',
                TITLE: 'Nedu Ottih',
                SUBTITLE: 'Board Member',
            }
        ]
    },
    ADVISORY_BOARD_PHOTO_GALLERY_GRID: {
        HEADER: 'ADVISORY BOARD MEMBERS',
        GALLERYIMAGES: [
            {
                IMAGE: '/images/board-images/Hilda-Pinnix-Ragland.png',
                TITLE: 'Hilda Pinnix-Ragland',
                SUBTITLE: 'Advisory Board Member',
            },
            {
                IMAGE: '/images/board-images/Joe-Crowley.png',
                TITLE: 'Congressman Joe Crowley',
                SUBTITLE: 'Advisory Board Member',
            },
            {
                IMAGE: '/images/board-images/Eric-Palm.png',
                TITLE: 'Eric Palm',
                SUBTITLE: 'Advisory Board Member',
            },
            {
                IMAGE: '/images/board-images/Mary-Ann-Laconte.png',
                TITLE: 'Mary Ann Laconte',
                SUBTITLE: 'Advisory Board Member',
            },
            {
                IMAGE: '/images/board-images/Michael-Nutter.png',
                TITLE: 'Mayor Michael Nutter',
                SUBTITLE: 'Advisory Board Member',
            },
            {
                IMAGE: '/images/board-images/Stephen-Goldsmith.png',
                TITLE: 'Mayor Stephen Goldsmith',
                SUBTITLE: 'Advisory Board Member',
            },
        ]
    },
    HOME_HERO: {
        MAIN_HEADER: 'HOME SOLUTIONS FOR PEACE OF MIND',
        SUB_HEADER: 'Oncourse Home Solutions brings peace of mind to homeowners with home protection plans and on-demand services that simply work.',
    },
    HOME_SIMPLE_BANNER_STRING: 'We address home-related headaches without the hassle, making homeownership enjoyable for all.',
    HOME_STATS_BANNER: [
        {
            MAIN_HEADER: '1.4 M',
            SUB_HEADER: 'Customers'
        },
        {
            MAIN_HEADER: '35',
            SUB_HEADER: 'Partners'
        },
        {
            MAIN_HEADER: '450+',
            SUB_HEADER: 'Team members'
        },
        {
            MAIN_HEADER: '500+',
            SUB_HEADER: 'Service providers'
        }
    ],
    HOME_IMAGETEXT: {
        MAIN_HEADER: 'SERVING HOMEOWNERS ACROSS THE UNITED STATES',
        SUB_HEADER: 'With decades of experience, Oncourse Home Solutions has established itself as a trusted provider of affordable and reliable home warranty solutions. By combining our capabilities from American Water Resources, Pivotal Home Solutions, and American Home Solutions, our new brand enables us to cover more areas across the U.S.A. and offer greater home protection for our customers.',
        SUB_HEADER2: 'Our goal is to simplify and enhance home care, ensuring exceptional service and peace of mind for homeowners nationwide.'
    },
    HOME_GRADIENTBANNER_HEADER: 'EXPLORE OUR BRANDS',
    HOME_CTABANNER_STRINGS: {
        MAIN_HEADER: 'GET IN TOUCH',
        SUB_HEADER: 'Interested in learning more about Oncourse Home Solutions? Reach out and we’ll be in touch shortly.',
    },
    HOMEOWNERS_HERO: {
        MAIN_HEADER: 'YOU\'RE SIMPLY COVERED',
        SUB_HEADER: 'At Oncourse Home Solutions, we\'re committed to protecting your home and serving you with utmost care.'
    },
    HOMEOWNERS_SIMPLE_BANNER: 'Our home protection plans and on-demand services are here to help you navigate the unexpected, reduce costs, and bring you peace of mind.',
    HOMEOWNERS_GRADIENT_BANNER: {
        HEADER: 'DISCOVER OUR HOMEOWNER BRANDS',
        SUB_HEADER: 'Let us safeguard your investment and take care of the unexpected, so you can focus on what truly matters — creating beautiful memories in your home.',
        CARDS: [
            {
                SRC: `/images/awr-logo.svg`,
                ALT: 'American Water Resources logo',
                CARD_HEADER: 'Nationwide home warranty coverage',
                CONTENT: 'American Water Resources offers home warranty plans for homeowners, including utility and municipal customers in 43 states since 2000. We cover: water lines, sewer lines, in-home plumbing, heating, cooling, and interior and exterior electric lines.',
                LINK: 'VISIT THE SITE',
                HREF: 'https://www.awrusa.com/'
            },
            {
                SRC: `/images/phs-logo.svg`,
                ALT: 'Pivotal Home Solutions logo',
                CARD_HEADER: 'Home warranty and repair services',
                CONTENT: 'Pivotal Home Solutions has provided home warranty protection for home systems, lines, and appliances in 16 states since 1992. Our service provider network includes expert technicians that handle heating, cooling, and plumbing repairs as needed. Pivotal Home Solutions is becoming Oncourse Home Solutions.',
                LINK: 'VISIT THE SITE',
                HREF: 'https://www.yourhomesolutions.com/'
            },
        ],
    },
    HOMEOWNERS_PROTECTIONS_BANNER: {
        HEADER: 'YOUR HOME IS OUR PRIORITY',
        SUB_HEADER: 'At Oncourse Home Solutions, we offer customizable home warranty solutions to protect your home and provide you with the peace of mind you deserve. From repairs to maintenance — whether it be water, sewer, and electric line to whole home protection — you can craft coverage that suits your needs.',
        ICONS: [
            {
                SRC: `/images/power-cord.svg`,
                LABEL: 'UTILITY LINES', 
            },
            {
                SRC: `/images/hvac.svg`,
                LABEL: 'HVAC', 
            },
            {
                SRC: `/images/plumbing.svg`,
                LABEL: 'PLUMBING PROTECTION', 
            },
            {
                SRC: `/images/appliances.svg`,
                LABEL: 'APPLIANCES', 
            },
            {
                SRC: `/images/surge.svg`,
                LABEL: 'ELECTRONICS & SURGE', 
            },
            {
                SRC: `/images/protection.svg`,
                LABEL: 'WHOLE HOME', 
            },
        ]
    },
    HOMEOWNERS_CTA_BANNER: {
        MAIN_HEADER: 'HEATING & AIR REPAIR SERVICES FOR ILLINOIS & NORTHWEST INDIANA',
        SUB_HEADER: 'If you face the intense weather of Illinois and Northwest Indiana, you know it’s essential to partner with a heating and air company. Oncourse is there to fix what you need when you need it. Rely on us for expert HVAC services, including repair, installation, and maintenance for all makes and models.'
    },
    HOMEOWNERS_GRADIENT_BANNER2: {
        HEADER: 'EXPLORE THE BENEFITS OF ONCOURSE HOME SOLUTIONS',
        CARDS: [
            {
                MAIN_HEADER: 'A nationwide network of more than 500 service providers.'
            },
            {
                MAIN_HEADER: 'Customizable solutions that fit your needs.'
            },
            {
                MAIN_HEADER: 'Partnerships with local utilities and municipalities.',
            },            
            {
                MAIN_HEADER: 'Water and sewer line coverage with regional utility providers.',
            },
            {
                MAIN_HEADER: 'Fast-response service specialists are available by phone 24/7/365.',
            },
            {
                MAIN_HEADER: 'Competitive coverage limits on plans.',
            },

        ]
    },
    HOMEOWNERS_STEPS_BANNER: {
        MAIN_HEADER: 'WE MAKE HOME PROTECTION EASY',
        STEPS: [
            {
                SRC: '/images/steps/step1.png',
                NUM_SRC: '/images/steps/num1.svg',
                CONTENT: 'Pick a plan that\'s right for you'
            },
            {
                SRC: '/images/steps/step2.png',
                NUM_SRC: '/images/steps/num2.svg',
                CONTENT: 'Get protection from repair costs and hassles'
            },
            {
                SRC: '/images/steps/step3.png',
                NUM_SRC: '/images/steps/num3.svg',
                CONTENT: 'Call if you need  help with home maintenance or repairs — we’ll take care of everything!'
            },
        ]
    },
    HOMEOWNERS_TESTIMONIALS: {
        MAIN_HEADER: 'HEAR DIRECTLY FROM OTHER HOMEOWNERS',
        SUB_HEADER: 'At Oncourse Home Solutions, we believe the best way to understand the quality of our services is through the experiences of those who have chosen us as their home care providers. We value the trust our customers place in us and it motivates us everyday to continue helping homeowners feel simply covered.',
        //FILTERS: ['American Water Resources', 'Pivotal Home Solutions'],
        CARDS: [
            {
                STARS: 5,
                QUOTE: '“Thank you for your prompt, efficient, courteous service. I have already told everyone I know they need to sign up for your services.”',
                NAME: 'Terry S.'
            },
            // {
            //     STARS: 5,
            //     QUOTE: '“I have nothing but praise for the speed with which my request was handled by the agents at American Water Resources of Florida and the speed and efficiency of the contracted service provider.”',
            //     NAME: 'Ronald B.'
            // },
            {
                STARS: 5,
                QUOTE: '“Plumber did an excellent job in a timely manner! I am so thankful for this program!”',
                NAME: 'Betty B.'
            },
            {
                STARS: 5,
                QUOTE: '“Unbelievable service, got the job done so quickly…I’m so happy I have this service, never thought I would use it but it shows you; you never know.”',
                NAME: 'Dorothy R.'
            },
        ]
    },
    HOMEOWNERS_CTA_BANNER2: {
        MAIN_HEADER: 'GET IN TOUCH',
        SUB_HEADER: 'Interested in learning more about Oncourse Home Solutions? Reach out and we’ll be in touch shortly.'
    },
};

export default APPLICATION_CONSTANTS;